<template>
  <v-app>
    <notifications group="foo" />
    <GlobalLoader />
    <v-main>
      <router-view />
      <div class="footer-wrp" v-if="showFooter">
        <div class="land-footer">
          <v-row class="align-center">
            <v-col cols="12" sm="4" md="4">
              <div class="d-flex align-center footer-logo">
                <img
                  style="width: 120px; margin-top: 7px"
                  src="@/assets/logo_1.svg"
                  alt=""
                />
              </div>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="company-info">
              <div>BRAINETIC LIMITED</div>
              <div>Registration number: #HE459646</div>
              <div>Apostolou Matthaiou 18, 2049, Nicosia, Cyprus</div>
              <div>support@teachyoga.app</div>
              <div>+44 20 3769 2548</div>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="footer-links">
              <div class="">
                <div>
                  <b class="mb-2 ml-1">Legal</b>
                </div>
                <div>
                  <router-link to="/privacy-policy">
                    Privacy Policy
                  </router-link>
                  <router-link to="/terms-and-conditions">
                    Terms and Conditions
                  </router-link>
                  <router-link to="/terms-of-purchase">
                    Terms of Purchase
                  </router-link>
                  <router-link to="/refund-policy"> Refund Policy </router-link>

                  <router-link to="/membership"> Membership </router-link>
                  <router-link to="/membership-cancellation">
                    Membership Cancellation
                  </router-link>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="footer-end">
          <div>All rights reserved.</div>
          <div class="footer-end__images">
            <img src="@/assets/images/visa.png" alt="" />
            <img src="@/assets/images/mastercard.png" alt="" />
          </div>
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import GlobalLoader from "./components/GlobalLoader";
export default {
  name: "App",
  components: {
    GlobalLoader,
  },
  computed: {
    showFooter() {
      if (
        this.$route.name == "registration" ||
        this.$route.name == "login" ||
        this.$route.name == "LandingPage"
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    window.getApp = this;
  },
  data: () => ({}),
};
</script>

<style lang="scss">
.land-footer {
  width: 100%;
  background: #f5f5f5;
  display: flex;
  justify-content: space-between;
  padding: 12px 12px;
  align-items: center;
  .company-info {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #929292;
    div {
      text-align: center;
    }
  }
  a {
    color: #929292;
    text-decoration: none;
    font-size: 12px;
    margin: 0 6px;
    &:hover {
      opacity: 0.7;
    }
  }
}

.footer-end {
  padding: 6px 12px;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  align-items: center;
  &__images {
    img {
      width: 45px;
      margin-left: 12px;
    }
  }
}
@media (max-width: 700px) {
  .land-footer {
    .company-info {
      color: #929292;
      font-size: 11px;
    }
    a {
      font-size: 11px;
    }
  }
  .plans-section {
    .tariff-wrp {
      .tariff-item {
        img {
          width: 40px;
        }
        &__title {
          text-transform: uppercase;
          font-size: 16px;
          font-weight: 200;
        }
        &__prices {
          font-size: 24px;
          font-weight: 600;
        }
        &__description {
          text-align: left;
          font-weight: 200;
          padding-right: 12px;
        }
      }
    }
  }
}
@media (max-width: 600px) {
  .land-footer {
    .footer-logo {
      justify-content: center;
    }
  }
}
@media (max-width: 500px) {
  .land-footer {
    flex-direction: column;
    .company-info {
      text-align: center;
      margin: 12px 0;
    }
    .footer-links {
      flex-direction: row !important;
    }
  }
}
@media (max-width: 400px) {
  .land-footer {
    .company-info {
      color: #929292;
      font-size: 10px;
    }
    a {
      font-size: 10px;
    }
  }
}
</style>
